import { Injector } from '@angular/core';
import { CustomFuseNavigation } from '@app/layout/navbar/navbar.component';
import { PermissionService } from '@core/services/permission/permission.service';
import { EAdminPanelPermission, EHospistockPermission, EMaPUIPermission } from '@mapuilabs/mpl-interfaces';

const HsPharmacyMenuItems = [
    EHospistockPermission.PharmacyDrugPublication_SeeMenuItem,
    EHospistockPermission.PharmacyDrugDashboard_SeeMenuItem,
    EHospistockPermission.PharmacyMedicalDevicePublication_SeeMenuItem,
    EHospistockPermission.PharmacyMedicalDeviceDashboard_SeeMenuItem
];

const HsPurchaseGroupMenuItems = [
    EHospistockPermission.PurchaseGroupDrugPublication_SeeMenuItem,
    EHospistockPermission.PurchaseGroupDrugDashboard_SeeMenuItem,
    EHospistockPermission.PurchaseGroupDrugMarket_SeeMenuItem,
    EHospistockPermission.PurchaseGroupMedicalDevicePublication_SeeMenuItem,
    EHospistockPermission.PurchaseGroupMedicalDeviceDashboard_SeeMenuItem,
    EHospistockPermission.PurchaseGroupMedicalDeviceMarket_SeeMenuItem
];

const HsLaboMenuItems = [
    EHospistockPermission.LaboDrugPublication_SeeMenuItem,
    EHospistockPermission.LaboDrugDashboard_SeeMenuItem,
    EHospistockPermission.LaboDrugAlternative_SeeMenuItem,
    EHospistockPermission.LaboDrugStatistic_SeeMenuItem,
    EHospistockPermission.LaboMedicalDevicePublication_SeeMenuItem,
    EHospistockPermission.LaboMedicalDeviceDashboard_SeeMenuItem,
    EHospistockPermission.LaboMedicalDeviceStatistic_SeeMenuItem
];

const HsHasAccessToMultipleViews = (injector: Injector): boolean => {
    const permissionsByViews = [HsPharmacyMenuItems, HsPurchaseGroupMenuItems, HsLaboMenuItems];
    return permissionsByViews.filter((items) => injector.get(PermissionService).authorize(items)).length > 1;
};

export const navigation: CustomFuseNavigation[] = [
    {
        id: 'root',
        title: 'HOME.TITLE',
        type: 'item',
        icon: 'home',
        permissions: EMaPUIPermission.Home_SeeMenuItem,
        url: '/home'
    },
    {
        id: 'admin',
        title: 'ADMIN.TITLE',
        type: 'group',
        permissions: [
            EAdminPanelPermission.Cron_SeeMenuItem,
            EAdminPanelPermission.GHT_SeeMenuItem,
            EAdminPanelPermission.AdminPanel_SeeMenuItem,
            EAdminPanelPermission.Roles_SeeMenuItem,
            EAdminPanelPermission.Statistics_SeeMenuItem,
            EAdminPanelPermission.HsStockoutConfig_SeeMenuItem
        ],
        children: [
            {
                id: 'admin.panel',
                title: 'ADMIN.PANEL.TITLE',
                type: 'collapsable',
                icon: 'view_list',
                permissions: EAdminPanelPermission.AdminPanel_SeeMenuItem,
                children: [
                    {
                        id: 'admin.panel.ars',
                        title: 'ADMIN.PANEL.ARS.TITLE',
                        type: 'item',
                        icon: 'pin_drop',
                        classes: 'theme-purple-nav',
                        url: '/adm/ars'
                    },
                    {
                        id: 'admin.panel.hospitals',
                        title: 'ADMIN.PANEL.HOSPITALS.TITLE',
                        type: 'item',
                        icon: 'local_hospital',
                        classes: 'theme-purple-nav',
                        url: '/adm/hospital'
                    },
                    {
                        id: 'admin.panel.officines',
                        title: 'ADMIN.PANEL.PHARMACY.TITLE',
                        type: 'item',
                        icon: 'local_pharmacy',
                        classes: 'theme-purple-nav',
                        url: '/adm/pharmacy'
                    },
                    {
                        id: 'admin.panel.users',
                        title: 'ADMIN.PANEL.USERS.TITLE',
                        type: 'item',
                        icon: 'person',
                        classes: 'theme-purple-nav',
                        url: '/adm/member'
                    },
                    {
                        id: 'admin.panel.groups',
                        title: 'ADMIN.PANEL.GROUPS.TITLE',
                        type: 'item',
                        icon: 'groups',
                        classes: 'theme-purple-nav',
                        url: '/adm/group'
                    },
                    {
                        id: 'admin.panel.labos',
                        title: 'ADMIN.PANEL.LABOS.TITLE',
                        type: 'item',
                        svg: 'preparation',
                        classes: 'theme-purple-nav',
                        url: '/adm/labo'
                    },
                    {
                        id: 'admin.panel.purchase-groups',
                        title: 'ADMIN.PANEL.PURCHASE_GROUPS.TITLE',
                        type: 'item',
                        icon: 'shopping_cart',
                        classes: 'theme-purple-nav',
                        url: '/adm/purchase-group'
                    }
                ]
            },
            {
                id: 'mapui.adm.release-notes.details',
                title: 'ADMIN.RELEASE_NOTES.TITLE',
                type: 'item',
                classes: 'theme-purple-nav',
                icon: 'event_note',
                permissions: EAdminPanelPermission.AdminPanel_SeeMenuItem,
                url: '/adm/release-notes'
            },
            {
                id: 'admin.sandbox',
                title: 'ADMIN.SANDBOX.TITLE',
                type: 'item',
                icon: 'extension',
                classes: 'theme-purple-nav',
                permissions: EAdminPanelPermission.Cron_SeeMenuItem,
                url: '/adm/sandbox'
            },
            {
                id: 'admin.online-users',
                title: 'ADMIN.ONLINE_USERS.TITLE',
                type: 'item',
                icon: 'timelapse',
                classes: 'theme-purple-nav',
                permissions: EAdminPanelPermission.OnlineUsers_SeeMenuItem,
                url: '/adm/online-users'
            },
            {
                id: 'admin.cron',
                title: 'ADMIN.CRON_MANAGER.TITLE',
                type: 'item',
                icon: 'alarm',
                classes: 'theme-purple-nav',
                permissions: EAdminPanelPermission.Cron_SeeMenuItem,
                url: '/adm/cron'
            },
            {
                id: 'admin.roles',
                title: 'ADMIN.ROLES.TITLE',
                type: 'item',
                icon: 'check_circle',
                classes: 'theme-purple-nav',
                permissions: EAdminPanelPermission.AdminPanel_SeeMenuItem,
                url: '/adm/role'
            },
            {
                id: 'admin.hospistock',
                title: `HOSPISTOCK.TITLE`,
                type: 'collapsable',
                svg: 'cart-remove',
                permissions: EAdminPanelPermission.HsStockoutConfig_SeeMenuItem,
                children: [
                    {
                        id: 'admin.hospistock.drug',
                        title: `HOSPISTOCK.DRUGS.TITLE`,
                        type: 'collapsable',
                        svg: 'pill',
                        permissions: EAdminPanelPermission.HsStockoutConfig_SeeMenuItem,
                        children: [
                            {
                                id: 'admin.hospistock.drug.publication',
                                title: 'HOSPISTOCK.PUBLICATIONS.TITLE',
                                type: 'item',
                                svg: 'cart-remove',
                                classes: 'theme-purple-nav',
                                permissions: EAdminPanelPermission.HsStockoutConfig_SeeMenuItem,
                                url: '/adm/hospistock/drug'
                            }
                        ]
                    },
                    {
                        id: 'admin.hospistock.medical-device',
                        title: `HOSPISTOCK.MEDICAL_DEVICES.TITLE`,
                        type: 'collapsable',
                        icon: 'healing',
                        permissions: EAdminPanelPermission.HsStockoutConfig_SeeMenuItem,
                        children: [
                            {
                                id: 'admin.hospistock.medical-device.publication',
                                title: 'HOSPISTOCK.PUBLICATIONS.TITLE',
                                type: 'item',
                                svg: 'cart-remove',
                                classes: 'theme-purple-nav',
                                permissions: EAdminPanelPermission.HsStockoutConfig_SeeMenuItem,
                                url: '/adm/hospistock/medical-device'
                            }
                        ]
                    },
                    {
                        id: 'admin.hospistock.config',
                        title: 'ADMIN.HOSPISTOCK.CONFIG.TITLE',
                        type: 'item',
                        icon: 'settings',
                        classes: 'theme-purple-nav',
                        permissions: EAdminPanelPermission.HsStockoutConfig_SeeMenuItem,
                        url: '/adm/hospistock/config'
                    },
                    {
                        id: 'admin.hospistock.news',
                        title: 'ADMIN.HOSPISTOCK.NEWS.TITLE',
                        type: 'item',
                        icon: 'event_note',
                        classes: 'theme-purple-nav',
                        permissions: EAdminPanelPermission.HsStockoutConfig_SeeMenuItem,
                        url: '/adm/hospistock/news'
                    }
                ]
            }
        ]
    },

    // -----------------------------------------------------------------------------------------------------
    // @ Hospistock
    // -----------------------------------------------------------------------------------------------------

    {
        id: 'hospistock',
        title: 'HOSPISTOCK.TITLE',
        type: 'group',
        permissions: [...HsPharmacyMenuItems, ...HsPurchaseGroupMenuItems, ...HsLaboMenuItems],
        children: [
            // ---------------------------------------------------------------------------------------------
            // @ Labo
            // ---------------------------------------------------------------------------------------------

            // ----------------------------------
            // Flat menu

            // Drug
            {
                id: 'hospistock.labo.drug',
                title: 'HOSPISTOCK.DRUGS.TITLE',
                type: 'collapsable',
                svg: 'pill',
                displayFn: (injector) => {
                    const permissionService = injector.get(PermissionService);
                    const permissions = [
                        EHospistockPermission.LaboDrugDashboard_SeeMenuItem,
                        EHospistockPermission.LaboDrugStatistic_SeeMenuItem,
                        EHospistockPermission.LaboDrugPublication_SeeMenuItem,
                        EHospistockPermission.LaboDrugAlternative_SeeMenuItem
                    ];
                    return permissionService.authorize(permissions) && !HsHasAccessToMultipleViews(injector);
                },
                children: [
                    {
                        id: 'hospistock.labo.drug.dashboard',
                        title: 'HOSPISTOCK.DASHBOARD.TITLE',
                        type: 'item',
                        icon: 'dashboard',
                        classes: 'theme-indigo-nav',
                        permissions: EHospistockPermission.LaboDrugDashboard_SeeMenuItem,
                        url: '/hospistock/labo/drug/dashboard'
                    },
                    {
                        id: 'hospistock.labo.drug.statistic',
                        title: 'HOSPISTOCK.STATISTICS.TITLE',
                        type: 'item',
                        icon: 'analytics',
                        classes: 'theme-indigo-nav',
                        permissions: EHospistockPermission.LaboDrugStatistic_SeeMenuItem,
                        url: '/hospistock/labo/drug/statistic'
                    },
                    {
                        id: 'hospistock.labo.drug.publication',
                        title: 'HOSPISTOCK.PUBLICATIONS.TITLE',
                        type: 'item',
                        svg: 'cart-remove',
                        classes: 'theme-indigo-nav',
                        permissions: EHospistockPermission.LaboDrugPublication_SeeMenuItem,
                        url: '/hospistock/labo/drug/publication'
                    },
                    {
                        id: 'hospistock.labo.drug.alternatives',
                        title: 'HOSPISTOCK.ALTERNATIVES.TITLE',
                        type: 'item',
                        svg: 'cart-remove',
                        classes: 'theme-indigo-nav',
                        permissions: EHospistockPermission.LaboDrugAlternative_SeeMenuItem,
                        url: '/hospistock/labo/drug/alternative'
                    }
                ]
            },

            // Medical Device
            {
                id: 'hospistock.labo.medical-device',
                title: 'HOSPISTOCK.MEDICAL_DEVICES.TITLE',
                type: 'collapsable',
                icon: 'healing',
                displayFn: (injector) => {
                    const permissionService = injector.get(PermissionService);
                    const permissions = [
                        EHospistockPermission.LaboMedicalDeviceDashboard_SeeMenuItem,
                        EHospistockPermission.LaboMedicalDeviceStatistic_SeeMenuItem,
                        EHospistockPermission.LaboMedicalDevicePublication_SeeMenuItem
                    ];
                    return permissionService.authorize(permissions) && !HsHasAccessToMultipleViews(injector);
                },
                children: [
                    {
                        id: 'hospistock.labo.medical-device.dashboard',
                        title: 'HOSPISTOCK.DASHBOARD.TITLE',
                        type: 'item',
                        icon: 'dashboard',
                        classes: 'theme-indigo-nav',
                        permissions: EHospistockPermission.LaboMedicalDeviceDashboard_SeeMenuItem,
                        url: '/hospistock/labo/medical-device/dashboard'
                    },
                    {
                        id: 'hospistock.labo.medical-device.statistic',
                        title: 'HOSPISTOCK.STATISTICS.TITLE',
                        type: 'item',
                        icon: 'analytics',
                        classes: 'theme-indigo-nav',
                        permissions: EHospistockPermission.LaboMedicalDeviceStatistic_SeeMenuItem,
                        url: '/hospistock/labo/medical-device/statistic'
                    },
                    {
                        id: 'hospistock.labo.medical-device.publication',
                        title: 'HOSPISTOCK.PUBLICATIONS.TITLE',
                        type: 'item',
                        svg: 'cart-remove',
                        classes: 'theme-indigo-nav',
                        permissions: EHospistockPermission.LaboMedicalDevicePublication_SeeMenuItem,
                        url: '/hospistock/labo/medical-device/publication'
                    }
                ]
            },

            // ----------------------------------
            // Nested menu

            {
                id: 'hospistock.nested.labo',
                title: 'HOSPISTOCK.LABOS.TITLE',
                type: 'collapsable',
                svg: 'preparation',
                displayFn: (injector) => {
                    const permissionService = injector.get(PermissionService);
                    const permissions = [
                        EHospistockPermission.LaboDrugDashboard_SeeMenuItem,
                        EHospistockPermission.LaboDrugPublication_SeeMenuItem,
                        EHospistockPermission.LaboDrugAlternative_SeeMenuItem,
                        EHospistockPermission.LaboMedicalDeviceDashboard_SeeMenuItem,
                        EHospistockPermission.LaboMedicalDevicePublication_SeeMenuItem
                    ];
                    return permissionService.authorize(permissions) && HsHasAccessToMultipleViews(injector);
                },
                children: [
                    // Drug
                    {
                        id: 'hospistock.nested.labo.drug',
                        title: 'HOSPISTOCK.DRUGS.TITLE',
                        type: 'collapsable',
                        svg: 'pill',
                        permissions: [
                            EHospistockPermission.LaboDrugDashboard_SeeMenuItem,
                            EHospistockPermission.LaboDrugPublication_SeeMenuItem,
                            EHospistockPermission.LaboDrugAlternative_SeeMenuItem
                        ],
                        children: [
                            {
                                id: 'hospistock.nested.labo.drug.dashboard',
                                title: 'HOSPISTOCK.DASHBOARD.TITLE',
                                type: 'item',
                                icon: 'dashboard',
                                classes: 'theme-indigo-nav',
                                permissions: EHospistockPermission.LaboDrugDashboard_SeeMenuItem,
                                url: '/hospistock/labo/drug/dashboard'
                            },
                            {
                                id: 'hospistock.nested.labo.drug.statistic',
                                title: 'HOSPISTOCK.STATISTICS.TITLE',
                                type: 'item',
                                icon: 'analytics',
                                classes: 'theme-indigo-nav',
                                permissions: EHospistockPermission.LaboDrugStatistic_SeeMenuItem,
                                url: '/hospistock/labo/drug/statistic'
                            },
                            {
                                id: 'hospistock.nested.labo.drug.publication',
                                title: 'HOSPISTOCK.PUBLICATIONS.TITLE',
                                type: 'item',
                                svg: 'cart-remove',
                                classes: 'theme-indigo-nav',
                                permissions: EHospistockPermission.LaboDrugPublication_SeeMenuItem,
                                url: '/hospistock/labo/drug/publication'
                            },
                            {
                                id: 'hospistock.nested.labo.drug.alternatives',
                                title: 'HOSPISTOCK.ALTERNATIVES.TITLE',
                                type: 'item',
                                svg: 'cart-remove',
                                classes: 'theme-indigo-nav',
                                permissions: EHospistockPermission.LaboDrugAlternative_SeeMenuItem,
                                url: '/hospistock/labo/drug/alternative'
                            }
                        ]
                    },

                    // Medical Device
                    {
                        id: 'hospistock.nested.labo.medical-device',
                        title: 'HOSPISTOCK.MEDICAL_DEVICES.TITLE',
                        type: 'collapsable',
                        icon: 'healing',
                        permissions: [
                            EHospistockPermission.LaboMedicalDeviceDashboard_SeeMenuItem,
                            EHospistockPermission.LaboMedicalDevicePublication_SeeMenuItem
                        ],
                        children: [
                            {
                                id: 'hospistock.nested.labo.medical-device.dashboard',
                                title: 'HOSPISTOCK.DASHBOARD.TITLE',
                                type: 'item',
                                icon: 'dashboard',
                                classes: 'theme-indigo-nav',
                                permissions: EHospistockPermission.LaboMedicalDeviceDashboard_SeeMenuItem,
                                url: '/hospistock/labo/medical-device/dashboard'
                            },
                            {
                                id: 'hospistock.nested.labo.medical-device.publication',
                                title: 'HOSPISTOCK.PUBLICATIONS.TITLE',
                                type: 'item',
                                svg: 'cart-remove',
                                classes: 'theme-indigo-nav',
                                permissions: EHospistockPermission.LaboMedicalDevicePublication_SeeMenuItem,
                                url: '/hospistock/labo/medical-device/publication'
                            }
                        ]
                    }
                ]
            },

            // ---------------------------------------------------------------------------------------------
            // @ Purchase Group
            // ---------------------------------------------------------------------------------------------

            // ----------------------------------
            // Flat menu

            // Drug
            {
                id: 'hospistock.purchase-group.drug',
                title: 'HOSPISTOCK.DRUGS.TITLE',
                type: 'collapsable',
                svg: 'pill',
                displayFn: (injector) => {
                    const permissionService = injector.get(PermissionService);
                    const permissions = [
                        EHospistockPermission.PurchaseGroupDrugDashboard_SeeMenuItem,
                        EHospistockPermission.PurchaseGroupDrugMarket_SeeMenuItem,
                        EHospistockPermission.PurchaseGroupDrugPublication_SeeMenuItem
                    ];
                    return permissionService.authorize(permissions) && !HsHasAccessToMultipleViews(injector);
                },
                children: [
                    {
                        id: 'hospistock.purchase-group.drug.dashboard',
                        title: 'HOSPISTOCK.DASHBOARD.TITLE',
                        type: 'item',
                        icon: 'dashboard',
                        classes: 'theme-green-nav',
                        permissions: EHospistockPermission.PurchaseGroupDrugDashboard_SeeMenuItem,
                        url: '/hospistock/purchase-group/drug/dashboard'
                    },
                    {
                        id: 'hospistock.purchase-group.drug.market',
                        title: 'HOSPISTOCK.MARKET.TITLE',
                        type: 'item',
                        icon: 'import_contacts',
                        classes: 'theme-green-nav',
                        permissions: EHospistockPermission.PurchaseGroupDrugMarket_SeeMenuItem,
                        url: '/hospistock/purchase-group/drug/market'
                    },
                    {
                        id: 'hospistock.purchase-group.drug.publication.dual',
                        title: 'HOSPISTOCK.PUBLICATIONS.TITLE',
                        type: 'item',
                        svg: 'cart-remove',
                        classes: 'theme-green-nav',
                        permissions: EHospistockPermission.PurchaseGroupDrugPublication_SeeMenuItem,
                        url: '/hospistock/purchase-group/drug/publication/dual'
                    }
                ]
            },

            // Medical Device
            {
                id: 'hospistock.purchase-group.medical-device',
                title: 'HOSPISTOCK.MEDICAL_DEVICES.TITLE',
                type: 'collapsable',
                icon: 'healing',
                displayFn: (injector) => {
                    const permissionService = injector.get(PermissionService);
                    const permissions = [
                        EHospistockPermission.PurchaseGroupMedicalDeviceDashboard_SeeMenuItem,
                        EHospistockPermission.PurchaseGroupMedicalDeviceMarket_SeeMenuItem,
                        EHospistockPermission.PurchaseGroupMedicalDevicePublication_SeeMenuItem
                    ];
                    return permissionService.authorize(permissions) && !HsHasAccessToMultipleViews(injector);
                },
                children: [
                    {
                        id: 'hospistock.purchase-group.medical-device.dashboard',
                        title: 'HOSPISTOCK.DASHBOARD.TITLE',
                        type: 'item',
                        icon: 'dashboard',
                        classes: 'theme-green-nav',
                        permissions: EHospistockPermission.PurchaseGroupMedicalDeviceDashboard_SeeMenuItem,
                        url: '/hospistock/purchase-group/medical-device/dashboard'
                    },
                    {
                        id: 'hospistock.purchase-group.medical-device.market',
                        title: 'HOSPISTOCK.MARKET.TITLE',
                        type: 'item',
                        icon: 'import_contacts',
                        classes: 'theme-green-nav',
                        permissions: EHospistockPermission.PurchaseGroupMedicalDeviceMarket_SeeMenuItem,
                        url: '/hospistock/purchase-group/medical-device/market'
                    },
                    {
                        id: 'hospistock.purchase-group.medical-device.publication.dual',
                        title: 'HOSPISTOCK.PUBLICATIONS.TITLE',
                        type: 'item',
                        svg: 'cart-remove',
                        classes: 'theme-green-nav',
                        permissions: EHospistockPermission.PurchaseGroupMedicalDevicePublication_SeeMenuItem,
                        url: '/hospistock/purchase-group/medical-device/publication/dual'
                    }
                ]
            },

            // ----------------------------------
            // Nested menu

            {
                id: 'hospistock.nested.purchase-group',
                title: 'HOSPISTOCK.PURCHASE_GROUP.TITLE',
                type: 'collapsable',
                icon: 'group',
                displayFn: (injector) => {
                    const permissionService = injector.get(PermissionService);
                    const permissions = [
                        EHospistockPermission.PurchaseGroupDrugDashboard_SeeMenuItem,
                        EHospistockPermission.PurchaseGroupDrugMarket_SeeMenuItem,
                        EHospistockPermission.PurchaseGroupDrugPublication_SeeMenuItem,
                        EHospistockPermission.PurchaseGroupMedicalDeviceDashboard_SeeMenuItem,
                        EHospistockPermission.PurchaseGroupMedicalDeviceMarket_SeeMenuItem,
                        EHospistockPermission.PurchaseGroupMedicalDevicePublication_SeeMenuItem
                    ];
                    return permissionService.authorize(permissions) && HsHasAccessToMultipleViews(injector);
                },
                children: [
                    // Drug
                    {
                        id: 'hospistock.nested.purchase-group.drug',
                        title: 'HOSPISTOCK.DRUGS.TITLE',
                        type: 'collapsable',
                        svg: 'pill',
                        permissions: [
                            EHospistockPermission.PurchaseGroupDrugDashboard_SeeMenuItem,
                            EHospistockPermission.PurchaseGroupDrugMarket_SeeMenuItem,
                            EHospistockPermission.PurchaseGroupDrugPublication_SeeMenuItem
                        ],
                        children: [
                            {
                                id: 'hospistock.nested.purchase-group.drug.dashboard',
                                title: 'HOSPISTOCK.DASHBOARD.TITLE',
                                type: 'item',
                                icon: 'dashboard',
                                classes: 'theme-green-nav',
                                permissions: EHospistockPermission.PurchaseGroupDrugDashboard_SeeMenuItem,
                                url: '/hospistock/purchase-group/drug/dashboard'
                            },
                            {
                                id: 'hospistock.nested.purchase-group.drug.market',
                                title: 'HOSPISTOCK.MARKET.TITLE',
                                type: 'item',
                                icon: 'import_contacts',
                                classes: 'theme-green-nav',
                                permissions: EHospistockPermission.PurchaseGroupDrugMarket_SeeMenuItem,
                                url: '/hospistock/purchase-group/drug/market'
                            },
                            {
                                id: 'hospistock.nested.purchase-group.drug.publication.dual',
                                title: 'HOSPISTOCK.PUBLICATIONS.TITLE',
                                type: 'item',
                                svg: 'cart-remove',
                                classes: 'theme-green-nav',
                                permissions: EHospistockPermission.PurchaseGroupDrugPublication_SeeMenuItem,
                                url: '/hospistock/purchase-group/drug/publication/dual'
                            }
                        ]
                    },

                    // Medical Device
                    {
                        id: 'hospistock.nested.purchase-group.medical-device',
                        title: 'HOSPISTOCK.MEDICAL_DEVICES.TITLE',
                        type: 'collapsable',
                        icon: 'healing',
                        permissions: [
                            EHospistockPermission.PurchaseGroupMedicalDeviceDashboard_SeeMenuItem,
                            EHospistockPermission.PurchaseGroupMedicalDeviceMarket_SeeMenuItem,
                            EHospistockPermission.PurchaseGroupMedicalDevicePublication_SeeMenuItem
                        ],
                        children: [
                            {
                                id: 'hospistock.nested.purchase-group.medical-device.dashboard',
                                title: 'HOSPISTOCK.DASHBOARD.TITLE',
                                type: 'item',
                                icon: 'dashboard',
                                classes: 'theme-green-nav',
                                permissions: EHospistockPermission.PurchaseGroupMedicalDeviceDashboard_SeeMenuItem,
                                url: '/hospistock/purchase-group/medical-device/dashboard'
                            },
                            {
                                id: 'hospistock.nested.purchase-group.medical-device.market',
                                title: 'HOSPISTOCK.MARKET.TITLE',
                                type: 'item',
                                icon: 'import_contacts',
                                classes: 'theme-green-nav',
                                permissions: EHospistockPermission.PurchaseGroupMedicalDeviceMarket_SeeMenuItem,
                                url: '/hospistock/purchase-group/medical-device/market'
                            },
                            {
                                id: 'hospistock.nested.purchase-group.medical-device.publication.dual',
                                title: 'HOSPISTOCK.PUBLICATIONS.TITLE',
                                type: 'item',
                                svg: 'cart-remove',
                                classes: 'theme-green-nav',
                                permissions: EHospistockPermission.PurchaseGroupMedicalDevicePublication_SeeMenuItem,
                                url: '/hospistock/purchase-group/medical-device/publication/dual'
                            }
                        ]
                    }
                ]
            },

            // ---------------------------------------------------------------------------------------------
            // @ Pharmacy
            // ---------------------------------------------------------------------------------------------

            // ----------------------------------
            // Flat menu

            // Drug
            {
                id: 'hospistock.pharmacy.drug',
                title: 'HOSPISTOCK.DRUGS.TITLE',
                type: 'collapsable',
                svg: 'pill',
                displayFn: (injector) => {
                    const permissionService = injector.get(PermissionService);
                    const permissions = [
                        EHospistockPermission.PharmacyDrugDashboard_SeeMenuItem,
                        EHospistockPermission.PharmacyDrugPublication_SeeMenuItem
                    ];
                    return permissionService.authorize(permissions) && !HsHasAccessToMultipleViews(injector);
                },
                children: [
                    {
                        id: 'hospistock.pharmacy.drug.dashboard',
                        title: 'HOSPISTOCK.DASHBOARD.TITLE',
                        type: 'item',
                        icon: 'dashboard',
                        classes: 'theme-blue-nav',
                        permissions: EHospistockPermission.PharmacyDrugDashboard_SeeMenuItem,
                        url: '/hospistock/pharmacy/drug/dashboard'
                    },
                    {
                        id: 'hospistock.pharmacy.drug.publication.dual',
                        title: 'HOSPISTOCK.PUBLICATIONS.TITLE',
                        type: 'item',
                        svg: 'cart-remove',
                        classes: 'theme-blue-nav',
                        permissions: EHospistockPermission.PharmacyDrugPublication_SeeMenuItem,
                        url: '/hospistock/pharmacy/drug/publication/dual'
                    }
                ]
            },

            // Medical Device
            {
                id: 'hospistock.pharmacy.medical-device',
                title: 'HOSPISTOCK.MEDICAL_DEVICES.TITLE',
                type: 'collapsable',
                icon: 'healing',
                displayFn: (injector) => {
                    const permissionService = injector.get(PermissionService);
                    const permissions = [
                        EHospistockPermission.PharmacyMedicalDeviceDashboard_SeeMenuItem,
                        EHospistockPermission.PharmacyMedicalDevicePublication_SeeMenuItem
                    ];
                    return permissionService.authorize(permissions) && !HsHasAccessToMultipleViews(injector);
                },
                children: [
                    {
                        id: 'hospistock.pharmacy.medical-device.dashboard',
                        title: 'HOSPISTOCK.DASHBOARD.TITLE',
                        type: 'item',
                        icon: 'dashboard',
                        classes: 'theme-blue-nav',
                        permissions: EHospistockPermission.PharmacyMedicalDeviceDashboard_SeeMenuItem,
                        url: '/hospistock/pharmacy/medical-device/dashboard'
                    },
                    {
                        id: 'hospistock.pharmacy.medical-device.publication.dual',
                        title: 'HOSPISTOCK.PUBLICATIONS.TITLE',
                        type: 'item',
                        svg: 'cart-remove',
                        classes: 'theme-blue-nav',
                        permissions: EHospistockPermission.PharmacyMedicalDevicePublication_SeeMenuItem,
                        url: '/hospistock/pharmacy/medical-device/publication/dual'
                    }
                ]
            },

            // ----------------------------------
            // Nested menu

            {
                id: 'hospistock.nested.pharmacy',
                title: 'HOSPISTOCK.PUI.TITLE',
                type: 'collapsable',
                icon: 'local_hospital',
                displayFn: (injector) => {
                    const permissionService = injector.get(PermissionService);
                    const permissions = [
                        EHospistockPermission.PharmacyDrugDashboard_SeeMenuItem,
                        EHospistockPermission.PharmacyDrugPublication_SeeMenuItem,
                        EHospistockPermission.PharmacyMedicalDeviceDashboard_SeeMenuItem,
                        EHospistockPermission.PharmacyMedicalDevicePublication_SeeMenuItem
                    ];
                    return permissionService.authorize(permissions) && HsHasAccessToMultipleViews(injector);
                },
                children: [
                    // Drug
                    {
                        id: 'hospistock.nested.pharmacy.drug',
                        title: 'HOSPISTOCK.DRUGS.TITLE',
                        type: 'collapsable',
                        svg: 'pill',
                        permissions: [
                            EHospistockPermission.PharmacyDrugDashboard_SeeMenuItem,
                            EHospistockPermission.PharmacyDrugPublication_SeeMenuItem
                        ],
                        children: [
                            {
                                id: 'hospistock.nested.pharmacy.drug.dashboard',
                                title: 'HOSPISTOCK.DASHBOARD.TITLE',
                                type: 'item',
                                icon: 'dashboard',
                                classes: 'theme-blue-nav',
                                permissions: EHospistockPermission.PharmacyDrugDashboard_SeeMenuItem,
                                url: '/hospistock/pharmacy/drug/dashboard'
                            },
                            {
                                id: 'hospistock.nested.pharmacy.drug.publication.dual',
                                title: 'HOSPISTOCK.PUBLICATIONS.TITLE',
                                type: 'item',
                                svg: 'cart-remove',
                                classes: 'theme-blue-nav',
                                permissions: EHospistockPermission.PharmacyDrugPublication_SeeMenuItem,
                                url: '/hospistock/pharmacy/drug/publication/dual'
                            }
                        ]
                    },

                    // Medical Device
                    {
                        id: 'hospistock.nested.pharmacy.medical-device',
                        title: 'HOSPISTOCK.MEDICAL_DEVICES.TITLE',
                        type: 'collapsable',
                        icon: 'healing',
                        permissions: [
                            EHospistockPermission.PharmacyMedicalDeviceDashboard_SeeMenuItem,
                            EHospistockPermission.PharmacyMedicalDevicePublication_SeeMenuItem
                        ],
                        children: [
                            {
                                id: 'hospistock.nested.pharmacy.medical-device.dashboard',
                                title: 'HOSPISTOCK.DASHBOARD.TITLE',
                                type: 'item',
                                icon: 'dashboard',
                                classes: 'theme-blue-nav',
                                permissions: EHospistockPermission.PharmacyMedicalDeviceDashboard_SeeMenuItem,
                                url: '/hospistock/pharmacy/medical-device/dashboard'
                            },
                            {
                                id: 'hospistock.nested.pharmacy.medical-device.publication.dual',
                                title: 'HOSPISTOCK.PUBLICATIONS.TITLE',
                                type: 'item',
                                svg: 'cart-remove',
                                classes: 'theme-blue-nav',
                                permissions: EHospistockPermission.PharmacyMedicalDevicePublication_SeeMenuItem,
                                url: '/hospistock/pharmacy/medical-device/publication/dual'
                            }
                        ]
                    }
                ]
            }
        ]
    },

    // -----------------------------------------------------------------------------------------------------
    // @ Livret
    // -----------------------------------------------------------------------------------------------------
    {
        id: 'livret',
        title: 'BOOKLET.TITLE',
        type: 'group',
        permissions: [
            EMaPUIPermission.MedicalDeviceBooklet_SeeMenuItem,
            EMaPUIPermission.DrugBooklet_SeeMenuItem,
            EMaPUIPermission.SharedDrugConfig_SeeMenuItem,
            EMaPUIPermission.Preparations_SeeMenuItem,
            EMaPUIPermission.Equivalences_SeeMenuItem
        ],
        children: [
            {
                id: 'hospistock.pharmacy.drug.booklet',
                title: 'BOOKLET.DRUGS.TITLE',
                type: 'item',
                icon: 'import_contacts',
                classes: 'theme-blue-nav',
                permissions: EMaPUIPermission.DrugBooklet_SeeMenuItem,
                url: '/hospistock/pharmacy/drug/booklet'
            },
            {
                id: 'hospistock.pharmacy.medical-device.booklet',
                title: 'BOOKLET.MEDICAL_DEVICES.TITLE',
                type: 'item',
                icon: 'import_contacts',
                classes: 'theme-blue-nav',
                permissions: EMaPUIPermission.MedicalDeviceBooklet_SeeMenuItem,
                url: '/hospistock/pharmacy/medical-device/booklet'
            },
            {
                id: 'hospiboard',
                title: 'BOOKLET.SHARED_MEDICATIONS.TITLE',
                type: 'item',
                icon: 'settings',
                permissions: EMaPUIPermission.SharedDrugConfig_SeeMenuItem,
                url: '/hospiboard/pharmacy/settings'
            },
            {
                id: 'pharmacy.preparations',
                title: 'BOOKLET.PREPARATIONS.TITLE',
                type: 'item',
                svg: 'preparation',
                permissions: EMaPUIPermission.Preparations_SeeMenuItem,
                url: '/pharmacy/preparations'
            },
            {
                id: 'pharmacy.equivalences',
                title: 'BOOKLET.EQUIVALENCES.TITLE',
                type: 'item',
                icon: 'sync',
                permissions: EMaPUIPermission.Equivalences_SeeMenuItem,
                url: '/pharmacy/equivalences'
            }
        ]
    },

    // -----------------------------------------------------------------------------------------------------
    // @ Hospiboard
    // -----------------------------------------------------------------------------------------------------
    {
        id: 'hospiboard',
        title: 'HOSPIBOARD.TITLE',
        type: 'group',
        permissions: [EMaPUIPermission.DashboardLabo_SeeMenuItem],
        children: [
            {
                id: 'hospiboard.dashboard.labo',
                title: 'HOSPIBOARD.LOAN_MONITORING.TITLE',
                type: 'item',
                icon: 'dashboard',
                classes: 'theme-indigo-nav',
                permissions: [EMaPUIPermission.DashboardLabo_SeeMenuItem],
                url: '/hospiboard/labo/dashboard'
            }
        ]
    },

    {
        id: 'pharmacy',
        title: 'MAPUI.TITLE',
        type: 'group',
        permissions: [
            EMaPUIPermission.SharedDrugSearch_SeeMenuItem,
            EMaPUIPermission.Map_SeeMenuItem,
            EMaPUIPermission.Dashboard_SeeMenuItem,
            EMaPUIPermission.Billing_SeeMenuItem,
            EMaPUIPermission.Archives_SeeMenuItem
        ],
        children: [
            {
                id: 'hospiboard.search',
                title: 'MAPUI.PRODUCT_SEARCH.TITLE',
                type: 'item',
                icon: 'search',
                permissions: EMaPUIPermission.SharedDrugSearch_SeeMenuItem,
                url: '/hospiboard/pharmacy/search'
            },
            {
                id: 'hospiboard.map',
                title: 'MAPUI.MAP.TITLE',
                type: 'item',
                icon: 'explore',
                permissions: EMaPUIPermission.Map_SeeMenuItem,
                url: '/hospiboard/pharmacy/map'
            },
            {
                id: 'exchanges.dashboard',
                title: 'MAPUI.DASHBOARD.TITLE',
                type: 'item',
                icon: 'dashboard',
                permissions: EMaPUIPermission.Dashboard_SeeMenuItem,
                url: '/exchange/dashboard'
            },
            {
                id: 'exchanges.billing',
                title: 'MAPUI.PAYMENT_NOTICES.TITLE',
                type: 'item',
                icon: 'receipt_long',
                permissions: EMaPUIPermission.Billing_SeeMenuItem,
                url: '/exchange/billing'
            },
            {
                id: 'exchanges.archives',
                title: 'MAPUI.ARCHIVES.TITLE',
                type: 'item',
                icon: 'inbox',
                permissions: EMaPUIPermission.Archives_SeeMenuItem,
                url: '/exchange/archives'
            },
            {
                id: 'exchanges.statistics',
                title: 'MAPUI.STATISTICS.TITLE',
                type: 'item',
                icon: 'pie_chart',
                permissions: EMaPUIPermission.Dashboard_SeeMenuItem,
                url: '/exchange/statistics'
            }
        ]
    },
    {
        id: 'setting',
        title: 'SETTING.TITLE',
        type: 'group',
        children: [
            {
                id: 'setting.user-account',
                title: 'SETTING.USER_ACCOUNT.TITLE',
                type: 'item',
                icon: 'person',
                url: '/setting/user-account'
            },
            {
                id: 'setting.hospital-account',
                title: 'SETTING.HOSPITAL_ACCOUNT.TITLE',
                type: 'collapsable',
                icon: 'local_hospital',
                permissions: EMaPUIPermission.Establishment_SeeMenuItem,
                children: [
                    {
                        id: 'setting.hospital-account.information',
                        title: 'SETTING.HOSPITAL_ACCOUNT.INFORMATION.TITLE',
                        type: 'item',
                        icon: 'info',
                        permissions: EMaPUIPermission.Establishment_SeeMenuItem,
                        url: '/setting/hospital-account/information'
                    },
                    {
                        id: 'setting.hospital-account.team',
                        title: 'SETTING.HOSPITAL_ACCOUNT.TEAM.TITLE',
                        type: 'item',
                        svg: 'account_key',
                        permissions: EMaPUIPermission.Establishment_SeeMenuItem,
                        url: 'setting/hospital-account/team'
                    }
                ]
            },
            {
                id: 'settings.groups',
                title: 'SETTING.GROUPS.TITLE',
                type: 'item',
                icon: 'groups',
                permissions: [EMaPUIPermission.Groups_SeeMenuItem, ...HsPurchaseGroupMenuItems],
                url: '/setting/group'
            }
        ]
    }
];
